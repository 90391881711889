<template>
  <div class="yinsi">
    <van-nav-bar
      title="隐私政策"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="main">
      <p class="part">欢迎你使用我们的产品和服务！我们深知个人信息对你的重要性，你的信任对我们非常重要，我们将根据法律法规要求并参照行业最佳实践为你的个人信息安全提供充分保障。鉴于此，大咖货源制定本《隐私政策》（下称“本政策 /本隐私权政策”）以帮助你充分了解：</p>
      <p class="part">本政策适用于大咖货源提供的所有产品和服务。如大咖货源及关联公司（范围详见定义部分）的产品或服务中使用了大咖货源提供的产品或服务（例如使用大咖货源账户登录）但未设独立隐私权政策的，则本政策同样适用于该部分产品或服务。我们及关联公司就其向你提供的产品或服务单独设立有隐私权政策的，则相应产品或服务适用相应隐私政策。</p>
      <p class="part">需要特别说明的是，本隐私政策不适用于其他第三方通过前述网页或客户端或者小程序或公众号等向你提供的服务。例如你通过大咖货源使用第三方应用（如大咖货源插件市场）或者服务，或第三方服务和产品依托大咖货源产品向你提供服务时，你向第三方提供的信息不适用本政策。</p>
      <p class="part">在使用大咖货源各项产品或服务前，请你务必仔细阅读和理解本政策，特别是以粗体/粗体下划线标识的条款，你应重点阅读，确认你充分理解并同意后再开始使用。</p>
      <p class="part">如对本政策内容有任何疑问、意见或建议，你可与我们联系。</p>
      
      <p class="list">第一部分 定义</p>
      <p class="list">一、【大咖货源】大咖货源指研发并提供大咖货源产品和服务石家庄米叮商贸有限公司，及现在或未来设立的相关关联公司的单称或合称。</p>
      <p class="list">二、【关联公司】关联公司指石家庄米叮商贸有限公司的大咖货源服务提供者的关联公司。</p>
      <p class="list">三、【大咖货源服务】大咖货源服务指由大咖货源开发和服务的大咖货源微商城、联盟商城等全面帮助商家经营移动社交电商和全渠道新零售的SaaS软件产品和云服务，以及将大咖货源部分功能/服务作为第三方服务集成在大咖货源关联公司（见定义条款）和/或其他第三方软件和/或智能硬件而提供的服务。</p>
      <p class="list">四、【个人信息】个人信息指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。</p>
      <p class="list">五、【个人敏感信息】个人敏感信息指包括身份证件号码、手机号码、个人生物识别信息、银行帐号、财产信息、行踪轨迹、交易信息、14岁以下（含）儿童的个人信息。</p>
      <p class="list">六、【个人信息删除】个人信息删除指在实现日常业务功能所涉及的系统中去除个人信息的行为，使其保持不可被检索、访问的状态。</p>
      <p class="list">七、除另有约定外，本政策所用定义与《用户协议》中的定义具有相同的涵义。</p>
      
      <p class="list">第二部分 隐私政策</p>
      <p class="list">一、我们如何收集和使用你的个人信息；</p>
      <p class="list">二、我们如何使用Cookie和同类技术；</p>
      <p class="list">三、我们如何共享、转让、公开披露你的个人信息；</p>
      <p class="list">四、我们如何保护你的个人信息；</p>
      <p class="list">五、你如何行使你的个人信息权利；</p>
      <p class="list">六、我们如何处理儿童的个人信息；</p>
      <p class="list">七、你的个人信息如何在全球范围转移；</p>
      <p class="list">八、本政策如何更新；</p>
      <p class="list">九、如何联系我们。</p>
      
      <p class="list">一、我们如何收集和使用你的个人信息</p>
      <p class="part">在你使用我们的产品及/或服务时，我们需要/可能需要收集和使用的你的个人信息包括如下两种：</p>
      <p class="list">1.为实现向你提供我们产品及/或服务的基本功能，你须授权我们收集、使用的必要的信息。如你拒绝提供相应信息，你将无法正常使用我们的产品及/或服务；</p>
      <p class="list">2.为实现向你提供我们产品及/或服务的附加功能，你可选择授权我们收集、使用的信息。如你拒绝提供，你将无法正常使用相关附加功能或无法达到我们拟达到的功能效果，但并不会影响你正常使用我们产品及/或服务的基本功能。</p>
      <p class="list">你理解并同意：</p>
      <p class="part">为给你带来更好的产品和服务体验，我们在持续努力改进我们的技术，随之我们可能会不时推出新的或优化后的功能，可能需要收集、使用新的个人信息或变更个人信息使用目的或方式。对此，我们将通过更新本政策、弹窗、页面提示等方式另行向你说明对应信息的收集目的、范围及使用方式，并为你提供自主选择同意的方式，且在征得你同意后收集、使用。</p>
      <p class="list">我们会出于本政策所述的以下目的，收集和使用你的个人信息：</p>
      <p class="list">（一）帮助你成为我们的用户</p>
      <p class="list">1.基础服务</p>
      <p class="part">当你注册成为大咖货源用户时，你需要提供手机号码及密码用于创建大咖货源账户。如果你仅需使用浏览服务，你不需要注册成为我们的用户及提供上述信息。</p>
      <p class="part">当你下单购买大咖货源商家商品/服务时，你需关注下单页面的协议签署内容；当你提交订单时，提交订单的行为代表着你同意本协议且授权商家使用你的收货相关信息，以使商家准确发货。</p>
      <p class="part">若存在依法需确定你身份的场景（包括依法为你扣缴税费、行政执法或司法诉讼中相关主体认定等）时，你授权我们可自大咖货源支付处获取你对应账户的相关认证信息用于上述目的。</p>
      <p class="list">2.附加服务</p>
      <p class="part">特别权益：如果你选择提供真实姓名、性别、出生年月日、居住地、昵称、头像、身份证号、微信号以及商家自定义需要你填写的非注册必须的个人资料，我们可以为你提供个性化的服务。其中，为保证交易辨识度，你的昵称、头像将公开显示。</p>
      <p class="part">授权登录：我们可能经你同意后向第三方共享你的账户信息（头像、昵称及其他页面提示的信息），使你可以便捷地实现第三方账户的注册或登录。此外，我们可能会根据你的授权从第三方处获取你的第三方账户信息，并与你的大咖货源账户进行绑定，使你可通过第三方账户直接登录、使用我们的产品及/或服务。我们将在你授权同意的范围内使用你的相关信息。</p>
      <p class="list">3.账户信息展示：如果你已拥有大咖货源账户，我们可能会在大咖货源商家端中显示你的上述个人信息，以及你在大咖货源上或与大咖货源账户相关联的产品和服务中执行的操作，包括通过个人中心页面集中展示你的个人资料、优惠权益、交易订单。我们会尊重你对大咖货源个人中心账户所做的选择。</p>
      <p class="list">（二）为你提供商品或服务信息展示</p>
      <p class="part">在你使用我们服务过程中，为识别账号异常状态，我们可能会自动收集你的使用情况并存储为网络日志信息，包括：</p>
      <p class="part">设备信息：我们会根据你在软件安装及/或使用中的具体操作，接收并记录你所使用的设备相关信息（包括MAC地址、设备型号、操作系统版本、设备设置、唯一设备标识符、设备环境、移动应用列表软硬件特征信息）、设备所在位置相关信息（包括你授权的GPS位置以及WLAN接入点和基站传感器信息）。</p>
      <p class="part">服务日志信息：当你使用我们的网站或客户端提供的产品或服务时，我们会自动收集你的详细使用情况，作为服务日志保存，包括浏览、点击查看、搜索查询、收藏、添加至购物车、交易、售后、关注分享信息、发布信息，以及IP地址、浏览器类型、电信运营商、使用语言、访问日期和时间</p>。
      <p class="part">为了解产品适配性，我们会根据你在软件安装及/或使用中的具体操作，接收并记录你所使用的设备相关信息（包括MAC地址、设备型号、操作系统版本、设备设置、唯一设备标识符、设备环境、移动应用列表软硬件特征信息）。</p>
      <p class="part">为向你提供更便捷、更符合你个性化需求的信息展示、搜索及推送服务，我们会自动收集你的详细使用情况，作为服务日志保存，包括浏览、点击查看、搜索查询、收藏、添加至购物车、交易、售后、关注分享信息、发布信息，以及IP地址、浏览器类型、电信运营商、使用语言、访问日期和时间，提取你的偏好特征，并基于特征标签产出间接人群画像，用于展示、推送信息和可能的商业广告。你可以通过关闭个性化推荐开关，关闭后我们将不再向你提供前述个性化服务。</p> 
      <p class="list">（三）为你提供认领、收藏、加购、关注与分享功能</p>
      <p class="part">在你浏览商家店铺或大咖货源网站/客户端的过程中，你可以选择对感兴趣的商品及/或服务进行收藏、添加至购物车、与商家建立会员关系、认领商家/大咖货源发放的各类卡券、通过我们提供的功能组件向其他第三方分享信息。在你使用上述功能的过程中，我们会收集包括你的收藏及添加购物车的记录、关注关系、分享历史在内的服务日志信息用于实现上述功能及其他我们明确告知的目的。</p>
      <p class="part">当你实现认领、收藏、加购、关注与分享功能时，商家可能会通过弹窗、页面提示等方式在获得你明示同意的情况下获取你的手机号、头像、昵称授权。</p>
      <p class="list">（四）帮助你完成下单及订单管理</p>
      <p class="part">当你在商家店铺或大咖货源网站/客户端中订购具体商品及/或服务时，我们会通过系统为你生成购买该商品及/或服务的订单。在下单过程中,你需至少提供你的收货人姓名、收货地址、收货人联系电话，同时该订单中会载明你所购买的商品及/或服务信息、具体订单号、订单创建时间、你应支付的金额，我们收集这些信息是为了帮助你顺利完成交易、保障你的交易安全、查询订单信息、提供客服与售后服务及其他我们明确告知的目的。</p>
      <p class="part">你可以为其他人订购商品及/或服务，你需要提供该实际订购人的前述个人信息，若其中涉及儿童个人信息的，你需在提供前征得对应儿童监护人的同意。</p>
      <p class="part">为便于你了解查询订单信息并对订单信息进行管理，我们会收集你在使用我们服务过程中产生的订单信息用于向你展示及便于你对订单进行管理。</p> 
      <p class="part">你可额外填写/选择包括其他联系电话、收货时间在内的更多附加信息以确保商品或服务的准确送达。</p>
      <p class="list">（五）帮助你完成支付</p>
      <p class="part">为完成订单支付，你需要选择付款方式，你需选择第三方支付公司或金融机构为你提供支付服务，我们将通过SDK的方式与第三方支付公司共享你的订单支付相关信息、订单安全相关设备信息及其他反洗钱法律要求的必要信息；若你选择金融机构进行支付，我们还会将你的包括银行卡号、有效期在内的银行卡支付必要信息与你选择的相应金融机构共享。</p>
      <p class="part">为使我们及时获悉并确认你的支付进度及状态，为你提供售后与争议解决服务，你同意我们可自你所选择的交易对象、支付公司或你选择的其他金融机构处收集与支付进度相关信息。</p>
      <p class="list">（六）帮助向你完成商品或服务的交付</p>
      <p class="part">为保证你购买的商品及/或服务能够顺利、安全、准确送达，我们会向为大咖货源及大咖货源商家提供物流信息系统和技术服务的供应链公司披露订单相关配送信息，并由其根据商品及/或服务提供主体的指定向相应的物流配送主体同步相关配送信息。你知晓并同意相应物流配送主体不可避免地获知及使用你的配送信息，用于完成交付目的。</p>
      <p class="part">为使我们及时获悉并确认交付进度及状态，向你提供售后与争议解决服务，你同意我们可自物流相关服务主体处收集与交付进度相关信息。</p>
      <p class="list">（七）客服及争议处理</p>
      <p class="part">当你与我们联系或提出售中售后、争议纠纷处理申请时，为了保障你的账户及系统安全，我们需要你提供必要的个人信息（包括账号信息、订单信息）以核验你的身份。</p>
      <p class="part">为便于与你联系、尽快帮助你解决问题或记录相关问题的处理方案及结果，我们可能会保存你与我们的通信/通话记录及相关内容（包括账号信息、订单信息、你为了证明相关事实提供的其他信息，或你留下的联系方式信息），如果你针对具体订单进行咨询、投诉或提供建议的，我们会使用你的账号信息和订单信息。</p>
      <p class="part">为了提供服务及改进服务质量的合理需要，我们还可能使用的你的其他信息，包括你与客服联系时你提供的相关信息，你参与问卷调查时向我们发送的问卷答复信息。</p>
      <p class="list">（八）为你提供评论、问答等信息公开发布功能</p>
      <p class="part">你可通过我们为你提供的评论、问答、论坛、晒单分享及其他信息发布功能公开发布信息，包括作为会 员可发布图文/视频/直播内容、发表评价及问答内容，以及作为卖家发布商品及/或服务相关信息、店铺相关信息。</p>
      <p class="part">我们可能会根据你的会员类型及网络日志信息判断你是否可享受对应功能权限（例如只有曾购买过某商品的会员才可通过问大家对其他会员针对该商品的提问做出回答）。</p>
      <p class="part">请注意，你公开发布的信息中可能会涉及你或他人的个人信息甚至个人敏感信息，如你在评价时选择上传包含个人信息的图片。请你更加谨慎地考虑，是否在使用我们的服务时共享甚至公开分享相关信息。若你公开发布的信息中涉及儿童个人信息的，你需在发布前征得对应儿童监护人的同意。</p>
      <p class="list">（九）为你提供安全保障</p>
      <p class="part">为提高你使用我们及我们关联公司、合作伙伴提供服务的安全性，保护你或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或大咖货源相关协议规则的情况，我们在应用程序中嵌入我们关联公司开发的应用安全SDK收集你的设备信息、服务日志信息，并可能使用或整合你的会员信息、交易信息、设备信息、服务日志信息以及我们关联公司、合作伙伴取得你授权或依据法律共享的信息，来综合判断你账户及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。</p>
      <p class="list">（十）为你提供其他附加服务</p>
      <p class="part">为向你提供更便捷、更优质、个性化的产品及/或服务，努力提升你的体验，我们在向你提供的以下附加服务中可能会收集和使用你的个人信息。如果你不提供这些信息，不会影响你使用大咖货源的浏览、搜索、购买等基本服务，但你可能无法获得这些附加服务给你带来的用户体验。这些附加服务包括：</p>
      <p class="list">1.基于位置信息的个性化推荐服务：我们会在你开启位置权限后访问获取你的位置信息，根据你的位置信息为你提供更契合你需求的页面展示、产品及/或服务。</p>
      <p class="list">2.基于相机/摄像头的附加服务：你可在开启相机/摄像头权限后使用该功能进行扫码用于登录、购物、领取优惠信息，拍摄照片或视频用于评价、分享，以及特定场景下经你授权的人脸识别的功能。请你知晓，即使你已同意开启相机权限，我们也仅会在你主动点击客户端内相机图标或录制视频时通过相机获取照片信息。</p>
      <p class="list">3.基于相册（图片库/视频库）的图片/视频访问及上传的附加服务：你可在开启相册权限后使用该功能上传你的照片/图片/视频，以实现更换头像、发表评论/分享、拍照购物或与客服沟通提供证明等功能。我们可能会使用包含你所上传照片或图片的评论信息。为实现你的好友、活动优惠等链接分享需求，我们可能会在设备本地读取你最近一次主动保存的图片链接地址。</p>
      <p class="list">4.基于麦克风的语音技术相关附加服务：你可在开启麦克风权限后使用麦克风实现语音购物功能，或与客服联系或与客服机器人实现语音交互，在这些功能中我们会收集你的录音内容以识别你的购物需求，或响应你的客服及争议处理等需求。请你知晓，即使你已同意开启麦克风权限，我们也仅会在你主动点击客户端内麦克风图标或录制视频时通过麦克风获取语音信息。</p>
      <p class="list">5.基于通讯录信息的附加服务：我们将在你开启通讯录权限后收集你的通讯录信息使你在购物时可以更便利地取用你通讯录内的联系人信息，无需再手动输入，包括你可以直接为通讯录中的联系人手机号进行充值；此外，为提升你在使用我们产品及/或服务过程中的社交互动分享乐趣，与你认识的人分享购物体验，在经你同意的前提下，我们也可能对你联系人的姓名和电话号码进行加密收集，帮助你判断你的通讯录联系人是否同为我们的会员进而在大咖货源网为你们的交流建立联系，同时，会员可选择开启或关闭好友隐私权限决定自己是否可被其他会员通过手机号码搜索、联系。</p>
      <p class="list">6.基于日历的附加服务：在你开启我们可读取/写入你日历的权限后，我们将收集你的日历信息用于向你提供购物或领取权益相关记录及提醒。</p>
      <p class="list">7.基于电话状态的附加服务：为保障你的账号与使用安全，你需要授权我们读取本机识别码。</p>
      <p class="list">8.基于蓝牙的附件服务：为提供打印服务，你需要授权我们读取你的蓝牙配对、通讯数据及蓝牙订单传输数据。</p>
      <p class="list">9.基于挂载文件系统的附加服务：为提供保存文件（包括图片）服务，你需要授权我们读取SD卡信息。</p>
      <p class="list">10.基于运行自动接收开机通知/忽略电池优化/排序系统任务的附加服务：在你开启权限后，你可在开机时接受客服消息的推送。</p>
      <p class="list">11.基于拨打电话的附加服务：在你开启权限后，你可通过查看配送中的订单物流信息，选中配送人员电话号码，拨打电话。</p>
      <p class="list">12.基于访问系统账户列表的附加服务：如你是境外用户，你需要开启该权限我们才能读取信息以完成客服消息推送。</p>
      <p class="list">13.基于读取系统日志的附加服务：在你开启权限后，我们可以收集线上的崩溃问题产生的错误日志以排查错误，并不涉及用户个人信息。</p>
      <p class="list">14.基于读写系统设置的附加服务：在你开启权限后，我们可以记录你的系统配置信息，通过辅助定位到问题所在以排查问题。</p>
      <p class="list">15.基于改变当前配置的附加服务：在你开启权限后，可以实现弹框提示用户开启wifi，用户确认后，可以开启wifi。</p> 
      <p class="part">你理解并同意，1至8项的附加服务可能需要你在你的设备中开启你的位置信息（地理位置）、摄像头（相机）、相册（图片库）、麦克风（语音）、通讯录、日历、蓝牙及/或电话状态的访问权限，以实现这些权限所涉及信息的收集和使用。若你为iOS用户，你可在系统中自行决定这些权限的开启或关闭。请你注意，你开启任一权限即代表你授权我们可以收集和使用相关个人信息来为你提供对应服务，你一旦关闭任一权限即代表你取消了授权，我们将不再基于对应权限继续收集和使用相关个人信息，也无法为你提供该权限所对应的服务。但是，你关闭权限的决定不会影响此前基于你的授权所进行的信息收集及使用。</p>
      <p class="part">9-15项的附加服务需要你在你在设备中开启对应权限或者同意相应的授权，以实现这些权限所涉及信息的收集和使用。你可在移动设备系统中自行确定这些权限的开启或关闭。</p>
      <p class="list">（十一）与你取得联系</p>
      <p class="part">为了向你提供更全面的服务介绍、提供更高效更优质的服务体验，我们的工作人员可能会通过你的联系方式（手机号码、邮箱）与你取得联系。如你不同意我们的工作人员基于前述场景联系你，你可联系大咖货源客服进行处理。</p>
      <p class="list">（十二）其他</p>
      <p class="list">1.若你提供的信息中含有其他用户的个人信息，在向大咖货源提供这些个人信息之前，你需确保你已经取得合法的授权。若其中涉及儿童个人信息的，你需在发布前取得对应儿童监护人的同意，前述情形下监护人有权联系我们，要求更正或删除涉及儿童个人信息的内容。</p>
      <p class="list">2.若我们将信息用于本政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的，或者我们主动从第三方处获取你的个人信息，均会事先获得你的同意。</p>
      <p class="part">若我们从第三方处间接获取你的信息的，我们会在收集前明确以书面形式要求该第三方在已依法取得你同意后收集个人信息，并向你告知共享的信息内容，且涉及敏感信息的在提供给我们使用前需经过你的明确确认，要求第三方对个人信息来源的合法性和合规性作出承诺，如第三方有违反行为的，我们会明确要求对方承担相应法律责任；同时，我们的专业安全团队对个人信息会进行安全加固（包括敏感信息报备、敏感信息加密存储、访问权限控制等）。我们会使用不低于我们对自身用户个人信息同等的保护手段与措施对间接获取的个人信息进行保护。</p>
      <p class="list">3.征得授权同意的例外</p>
      <p class="part">你充分理解并同意，我们在以下情况下收集、使用你的个人信息无需你的授权同意，且我们可能不会响应你提出的更正/修改、删除、注销、撤回同意、索取信息的请求：</p>
      <p class="list">（1）与国家安全、国防安全有关的；</p>
      <p class="list">（2）与公共安全、公共卫生、重大公共利益有关的；</p>
      <p class="list">（3）与犯罪侦查、起诉、审判和判决执行等司法或行政执法有关的；</p>
      <p class="list">（4）出于维护你或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
      <p class="list">（5）你自行向社会公众公开的个人信息；</p>
      <p class="list">（6）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</p>
      <p class="list">（7）根据与你签订和履行相关协议或其他书面文件所必需的；</p>
      <p class="list">（8）用于维护所提供的产品及/或服务的安全稳定运行所必需的，例如发现、处置产品及/或服务的故障；</p>
      <p class="list">（9）为合法的新闻报道所必需的；</p>
      <p class="list">（10）学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；</p>
      <p class="list">（11）法律法规规定的其他情形。</p>
      <p class="part">请知悉，根据适用的法律，若我们对个人信息采取技术措施和其他必要措施进行处理，使得数据接收方无法重新识别特定个人且不能复原，或我们可能会对收集的信息进行去标识化地研究、统计分析和预测，用于改善大咖货源的内容和布局，为商业决策提供产品或服务支撑，以及改进我们的产品和服务（包括使用匿名数据进行机器学习或模型算法训练），则此类处理后数据的使用无需另行向你通知并征得你的同意。</p>
      <p class="list">4.如我们停止运营大咖货源产品或服务，我们将及时停止继续收集你个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知你，并对我们所持有的与已关停业务相关的个人信息进行删除或匿名化处理。涉及儿童个人信息的，我们会并将停止运营的通知及时告知儿童监护人。</p>
      <p class="list">（十三）请知悉，为保证向你提供本隐私政策所述的服务，我们会向关联公司、服务提供商及业务合作伙伴等第三方共享必要的个人信息。</p>
      <p class="part">这些第三方包括我们的物流服务提供商、支付服务提供商、广告和营销服务供应商以及其他业务合作伙伴。这些第三方可能代表大咖货源或出于本隐私政策的一项或多项目的处理你的个人信息。我们保证仅出于正当、合法、必要、特定、明确的目的共享为你提供服务所必要的个人信息。大咖货源将进行尽职调查并签订数据共享或数据处理协议，以确保第三方服务提供商遵守中国大陆的隐私法律。第三方服务提供商也可能拥有其子处理者。同时我们会采取必要的技术和管理措施尽可能保护你的个人信息安全。</p>
      <p class="part">为提供成效衡量、分析和其他商业服务，我们还可能以汇总的形式与第三方（例如我们网站上的广告商）共享信息（非个人信息）。我们使用所掌握的信息来帮助广告主和其他合作伙伴评估他们广告和服务的成效及覆盖情况，并了解使用他们服务的人群类型以及人们如何与其网站、应用和服务互动。</p>
      <p class="list">二、我们如何使用 Cookie 和同类技术</p>
      <p class="list">（一）Cookie的使用</p>
      <p class="part">为确保网站正常运转、为你获得更轻松的访问体验、向你推荐你可能感兴趣的内容，我们会在你的计算机或移动设备上存储名Cookie、Flash Cookie或浏览器或关联应用程序提供的其他通常包含标识符、站点名称以及一些号码和字符的本地存储（统称“Cookie”）。借助于Cookie，网站能够存储你的偏好或购物车内的商品数据。</p>
      <p class="part">如果你的浏览器或浏览器附加服务允许，你可修改对Cookie的接受程度或拒绝我们的Cookie。但如果你这么做，在某些情况下可能会影响你安全访问我们的网站，而无法使用部分我们提供的服务。</p> 
      <p class="list">（二）网站信标和像素标签</p>
      <p class="part">除Cookie外，我们还会在网站上使用网站信标和像素标签等其他同类技术。例如，我们向你发送的电子邮件可能含有链接至我们网站内容的地址链接，如果你点击该链接，我们则会跟踪此次点击，帮助我们了解你的产品或服务偏好以便于我们主动改善客户服务体验。网站信标通常是一种嵌入到网站或电子邮件中的透明图像。借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。如果你不希望自己的活动以这种方式被追踪，则可以随时从我们的寄信名单中退订。</p>
      <p class="list">三、我们如何共享、转让、公开披露你的个人信息</p>
      <p class="list">（一）共享</p>
      <p class="part">我们不会与大咖货源服务提供者以外的公司、组织和个人共享你的个人信息，但以下情况除外：</p>
      <p class="list">1.在你主动选择情况下共享：若你主动通过大咖货源购买商品和服务，我们会根据你的选择，将你的订单信息与交易有关的必要信息与相关商品和服务的提供者共享来实现你向其购买商品或服务的需求，并促使其可以完成后续的售后服务。你可以在相关商品和服务的提供者从事经营活动的主页面查询其营业执照登载的信息，以辨别你的交易对象身份。</p>
      <p class="list">2.在获取明确同意情况下共享：获得你的明确同意后，我们会与其他方共享你的个人信息。例如，如果我们委托第三方向你提供产品或服务时，我们会在征得你同意后将上述信息共享给第三方，如果你拒绝提供此类信息，我们将无法完成相关交付服务。</p>
      <p class="list">3.在法定情形下共享：我们可能会根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依法提出的要求，对外共享你的个人信息。</p>
      <p class="list">4.与关联公司共享：为便于我们基于大咖货源账户向你提供服务，推荐你可能感兴趣的信息或护大咖货源关联公司或其他用户或公众的人身财产安全免遭侵害，在你同意的情况下，你的个人信息可能会与我们的关联公司共享。我们只会共享必要的个人信息，且受本隐私政策中所声明目的的约束，如果我们共享你的个人敏感信息或关联公司改变个人信息的使用及处理目的，将再次征求你的授权同意。</p>
      <p class="list">5.与授权合作伙伴共享：我们会委托授权合作伙伴为你提供某些服务或代表我们履行职能，在委托前，我们将通过签订协议的方式征求你的同意，我们仅会出于合法、正当、必要、特定、明确的目的共享你的信息，授权合作伙伴只能接触到为其履行职责所需信息，且不能将此信息用于任何其他目的。</p>
      <p class="part">目前，我们的授权合作伙伴包括以下类型：</p>
      <p class="list">（1）为让使用我们SaaS软件的商家能够完成你所需要的服务，我们会将你的订单信息、支付信息、收货地址和联系人信息提供至商家。</p>
      <p class="list">（2）广告、分析服务类的授权合作伙伴。除非得到你的许可，否则我们不会将你的个人身份信息（指可以识别你身份的信息，例如姓名或电子邮箱，通过这些信息可以联系到你或识别你的身份）与提供广告、分析服务的合作伙伴共享。</p>
      <p class="list">（3）供应商、服务提供商和其他合作伙伴。我们将信息发送给支持我们业务的供应商、服务提供商和其他合作伙伴，这些支持包括提供技术基础设施服务、分析我们服务的使用方式、衡量服务的有效性、提供客户服务、支付便利或进行学术研究和调查。</p>
      <p class="part">对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的数据保护协议，要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。</p>
      <p class="list">（二）转让</p>
      <p class="part">我们不会将你的个人信息转让给任何公司、组织和个人，但以下情况除外：</p>
      <p class="list">1.在获取明确同意的情况下转让：获得你的明确同意后，我们会向其他方转让你的个人信息。</p>
      <p class="list">2.在大咖货源服务提供者发生合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到个人信息转让，我们会要求新的持有你个人信息的公司、组织继续受本政策的约束，否则我们将要求该公司、组织和个人重新向你征求授权同意。</p>
      <p class="list">（三）公开披露</p>
      <p class="part">我们仅会在以下情况下，公开披露你的个人信息：</p>
      <p class="list">1.获得你明确同意或基于你的主动选择，我们可能会公开披露你的个人信息；</p>
      <p class="list">2.如果我们确定你出现违反法律法规或严重违反大咖货源相关协议规则的情况，或为保护大咖货源及其关联公司用户或公众的人身财产安全免遭侵害，我们可能依据法律法规或大咖货源相关协议规则通过短信通知或者弹窗等方式征得你同意的情况下披露关于你的个人信息，包括相关违规行为以及大咖货源已对你采取的措施。例如，若你销售假货而严重违反大咖货源规则，我们可能会公开披露你的店铺主体信息与处罚情况。</p>
      <p class="list">（四）共享、转让、公开披露个人信息时事先征得授权同意的例外</p>
      <p class="part">以下情形中，共享、转让、公开披露你的个人信息无需事先征得你的授权同意：</p>
      <p class="list">1.根据法律法规的规定或行政、司法机构、国家部门等有权机关的要求依法提供的；</p>
      <p class="list">2.出于维护你或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
      <p class="list">3.你自行向社会公众公开的个人信息；</p>
      <p class="list">4.从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</p>
      <p class="part">根据法律规定，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向你通知并征得你的同意。</p>
      <p class="list">四、我们如何保护你的个人信息安全</p>
      <p class="list">（一）为保障你的信息安全，我们努力采取各种符合业界标准的的安全防护措施，包括建立合理的制度规范、实施合宜的安全技术来防止你的个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。</p>
      <p class="part">我们会使用受信赖的保护机制防止数据遭到恶意攻击，例如在你的浏览器与“服务”之间交换数据（如信用卡信息）时采用 SSL 加密保护技术，同时对网站本身提供 https 安全浏览方式，以保障你的数据在传输过程中的安全。使用加密技术对个人信息加密保存，确保数据的保密性。在个人信息使用时，例如订单查询、展示时，我们会采用多种脱敏技术增强个人信息在使用中的安全性。我们会采用严格的数据访问控制权限控制和多重身份验证机制，确保只有授权人员才可访问个人信息。同时，在数据使用过程中，我们提供数据安全审计，避免数据被违规使用。</p>
      <p class="list">（二）我们建立了数据安全管理规范来管理及规范个人信息的存储和使用，并通过审计机制对数据安全进行全面检查，保障个人信息安全。</p>
      <p class="list">（三）我们会采取合理可行的措施，尽力避免收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留你的个人信息，除非法律有强制的存留要求，例如《中华人民共和国电子商务法》要求商品和服务信息、交易信息保存时间自交易完成之日起不少于三年。而我们判断前述期限的标准包括：</p>
      <p class="list">1.完成与你相关的交易目的、维护相应交易及业务记录、应对你可能的查询或投诉；</p>
      <p class="list">2.保证我们为你提供服务的安全和质量；</p>
      <p class="list">3.你是否同意更长的留存期间；</p>
      <p class="list">4.是否存在保留期限的其他特别约定。</p>
      <p class="part">在你的个人信息超出保留期间后，我们会根据适用法律的要求删除你的个人信息，或使其匿名化处理。</p>
      <p class="list">（四）互联网并非绝对安全的环境，使用大咖货源服务时，我们强烈建议你不要使用非大咖货源推荐的通信方式发送你的信息。你可以通过我们的服务建立联系和相互分享。当你通过我们的服务创建交流、交易或分享时，你可以自主选择沟通、交易或分享的对象，作为能够看到你的交易内容、联络方式、交流信息或分享内容等相关信息的第三方。</p>
      <p class="part">在使用大咖货源进行网上交易时，你不可避免地要向交易对方或潜在的交易对方披露自己的个人信息，如联络方式或联系地址。请你妥善保护自己的个人信息，仅在必要的情形下向他人提供。如你发现自己的个人信息尤其是你的账户或密码发生泄露，请你立即联络大咖货源客服，以便我们根据你的申请采取相应措施。</p>
      <p class="part">请注意，你在使用我们服务时自愿共享甚至公开分享的信息，可能会涉及你或他人的个人信息甚至个人敏感信息。请你更加谨慎地考虑，是否在使用我们的服务时共享甚至公开分享相关信息。</p>
      <p class="list">（五）请使用复杂密码，协助我们保证你的账号安全。我们将尽力保障你发送给我们的任何信息的安全性。如果我们的物理、技术或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改或毁坏，导致你的合法权益受损，我们将承担相应的法律责任。</p>
      <p class="list">（六）在不幸发生个人信息安全事件后，我们将按照法律法规的要求向你告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、你可自主防范和降低风险的建议、对你的补救措施等。事件相关情况我们将以邮件、信函、电话、推送通知等方式告知你，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。</p>
      <p class="part">我们已制定儿童个人信息安全事件应急预案，定期组织内部相关人员进行应急响应培训和应急演练，使其掌握岗位职责和应急处置策略和规程。在不幸发生儿童个人信息安全事件后，我们将按照法律法规的要求，及时向儿童及其监护人告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、儿童及其监护人可自主防范和降低风险的建议、对儿童及其监护人的补救措施等。我们将及时将事件相关情况以APP推送通知、发送邮件/短消息等方式告知儿童及其监护人。难以逐一告知时，我们会采取合理、有效的方式发布相关警示信息。同时，我们还将按照监管部门要求，主动上报儿童个人信息安全事件的处置情况。若儿童及其监护人的合法权益受损，我们将依法承担相应的法律责任。</p>
      <p class="part">同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。</p>
      
      <p class="list">五、你如何管理你的信息</p>
      <p class="part">你可以通过以下方式访问及管理你的信息：</p>
      <p class="list">（一）查询、更正和补充你的信息</p>
      <p class="part">你有权查询、或补充你的信息。你可以通过以下方式自行进行：</p>
      <p class="list">1.进入商家店铺，选择“我的”，点击个人信息后查询、更正个人资料、个人账户相关信息，及对收货地址信息进行查询、更正或补充。</p>
      <p class="list">2.选择联系大咖货源客服400-1013505寻求帮助，协助你查询、更正或补充你的信息。</p>
      <p class="list">（二）删除你的信息</p>
      <p class="part">在以下情形中，你可以向我们提出删除个人信息的请求：</p>
      <p class="list">1.如果我们处理个人信息的行为违反法律法规；</p>
      <p class="list">2.如果我们收集、使用你的个人信息，却未征得你的明确同意；</p>
      <p class="list">3.如果我们处理个人信息的行为严重违反了与你的约定；</p>
      <p class="list">4.如果你不再使用我们的产品或服务，或你主动注销了账号；</p>
      <p class="list">5.如果我们停止为你提供产品或服务。</p>
      <p class="part">若我们决定响应你的删除请求，我们还将同时尽可能通知从我们处获得你的个人信息的主体，并要求其及时删除（除非法律法规另有规定，或这些主体已独立获得你的授权）。</p>
      <p class="part">当你或我们协助你删除相关信息后，因为适用的法律和安全技术，我们可能无法立即从备份系统中删除相应的信息，我们将安全地存储你的个人信息并将其与任何进一步处理隔离，直到备份可以清除或实现匿名。</p>
      <p class="list">（三）取消授权</p>
      <p class="part">每个业务功能需要一些基本的个人信息才能得以完成（见本隐私权政策“第一部分”）。除此之外，你可以在“个人信息”页面中设置或与客服联系等方式给予或收回你的授权同意</p>
      <p class="part">当你收回同意后，我们将不再处理相应的个人信息。但你收回同意的决定，不会影响此前基于你的授权而开展的个人信息处理。</p>
      <p class="list">（四）注销你的账户</p>
      <p class="part">你可以在我们的产品中直接申请注销账户：</p>
      <p class="part">关于你注销账户的方式以及你应满足的条件，请详见《重要提醒》。你注销账户后，我们将停止为你提供产品与/或服务，并依据你的要求，除法律法规另有规定外，我们将删除你的个人信息。</p>
      <p class="list">（五）约束信息系统自动决策</p>
      <p class="part">在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响你的合法权益，你有权要求我们做出解释，我们也将在不侵害大咖货源商业秘密或其他用户权益、社会公共利益的前提下提供申诉方法。</p>
      <p class="list">（六）响应你的上述请求</p>
      <p class="part">为保障安全，你可能需要提供书面请求，或以其他方式证明你的身份。我们可能会先要求你验证自己的身份，然后再处理你的请求。</p>
      <p class="part">我们将在15天内做出答复。如你不满意，还可以通过大咖货源客服发起投诉。</p>
      <p class="part">对于你合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将酌情收取一定费用。对于与你的身份不直接关联的信息、无端重复信息，或者需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者不切实际的请求，我们可能会予以拒绝。</p>
      <p class="part">在以下情形中，按照法律法规要求，我们将无法响应你的请求：</p>
      <p class="list">1.与国家安全、国防安全有关的；</p>
      <p class="list">2.与公共安全、公共卫生、重大公共利益有关的；</p>
      <p class="list">3.与犯罪侦查、起诉、审判和执行判决等有关的；</p>
      <p class="list">4.有充分证据表明个人信息主体存在主观恶意或滥用权利的；</p>
      <p class="list">5.响应你的请求将导致你或其他个人、组织的合法权益受到严重损害的；</p>
      <p class="list">6.涉及商业秘密的。</p>
      
      <p class="list">六、你的个人信息如何在全球范围转移</p>
      <p class="part">我们在中华人民共和国境内运营中收集和产生的个人信息，存储在中国境内，以下情形除外：</p>
      <p class="list">1.法律法规有明确规定；</p>
      <p class="list">2.获得你的明确授权；</p>
      <p class="list">3.你通过互联网进行跨境交易等个人主动行为。</p>
      <p class="part">针对以上情形，我们会确保依据本隐私政策对你的个人信息提供足够的保护。</p>
      
      <p class="list">七、我们如何处理儿童的个人信息</p>
      <p class="part">本政策所指的儿童是指不满14周岁的个人，如果没有父母或监护人的同意，儿童不得创建自己的用户账户。如你为未成年人的，建议你请你的父母或监护人仔细阅读本隐私政策，并在征得你的父母或监护人同意的前提下使用我们的服务或向我们提供信息。</p>
      <p class="part">对于经父母或监护人同意使用我们的产品或服务而收集未成年人个人信息的情况，我们只会在法律法规允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。如果我们发现自己在未事先获得可证实的父母同意的情况下收集了儿童的个人信息，则会设法尽快删除相关数据。</p>
      
      <p class="list">八、本隐私政策如何更新</p>
      <p class="part">我们的隐私权政策可能变更。</p>
      <p class="part">未经你明确同意，我们不会限制你按照本隐私权政策所应享有的权利。我们会在大咖货源规则中心专门页面上发布对隐私权政策所做的任何变更。</p>
      <p class="part">对于重大变更，我们还会提供更为显著的通知（包括我们会通过在大咖货源规则中心公示的方式进行通知甚至向你提供弹窗提示）。</p>
      <p class="part">本政策所指的重大变更包括但不限于：</p>
      <p class="list">1.我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；</p>
      <p class="list">2.我们在控制权等方面发生重大变化。如并购重组等引起的信息控制者变更等；</p>
      <p class="list">3.个人信息共享、转让或公开披露的主要对象发生变化；</p>
      <p class="list">4.你参与个人信息处理方面的权利及其行使方式发生重大变化；</p>
      <p class="list">5.我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化；</p>
      <p class="list">6.个人信息安全影响评估报告表明存在高风险。</p>
      
      <p class="list">九、如何联系我们</p>
      <p class="part">我们设立了个人信息保护部门，如对本政策内容有任何疑问、意见或建议，或发现你的个人信息可能被泄露或你觉得需要撤回、更正、删除你的个人信息授权，你可以发邮件到midbestvip@163.com与我们联系，我们将在15天内回复你的请求。</p>
      <p class="part">如果你对我们的回复不满意，特别是你认为我们的个人信息处理行为损害了你的合法权益，你还可以通过被告住所地有管辖权的法院提起诉讼来寻求解决方案。</p>
      <p class="part">注：本隐私政策版本更新日期为2020年12月4日，于2020年12月11日正式生效。</p>
    </div>
  </div>
</template>
<script>
export default {
  name: "yinSi",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      shareid: 0,
      merchid: 0,
    }
  },
  mounted() {
    this.islogin = localStorage.getItem("DK_ISLOGIN") ? true : false;
    if (this.$route.query.shareid) {
      this.shareid = this.$route.query.shareid
    }else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;
    this.$parent.getmerchname(this.merchid, "隐私政策");
  },
  methods: {
    // 返回上一页
    onClickLeft() {
      this.$router.back();
    },
  },
}
</script>
<style lang="less">
.yinsi {
  overflow: hidden;
  width: 100%;
  min-height: 100%;
  position: relative;
  z-index: 2;
  background-color: #fff;
  .van-nav-bar .van-icon {
    color: #333;
  }
  .main {
    padding: 10px;
    overflow: hidden;
    text-align: left;
    font-size: 14px;
    color: #333;
    line-height: 20px;
    .part {
      text-indent: 30px;
    }
  }
}
</style>